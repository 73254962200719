<template>
    <inner-page-card :route-path="lineBackPath" heading="User Details" :link-back="true" class="mt-3">

        <div v-if="loading" class="text-center card">
            <loading-animation/>
            <div class="pt-3 pb-3">Please wait while data is being loaded...</div>
        </div>

        <div v-if="details && !loading">

            <table class="table table-bordered border-r-2 mb-3">
                <thead>
                <tr class="bg-1">
                    <th rowspan="1" colspan="2" class="pos-r">
                        <div class="font-inter-medium text-center fs-lg-2">
                            User Id - {{ details.id }}
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="section-items">
                <tr>
                    <td class="font-inter-medium w-100">
                        <div>
                            <span class="font-inter-semi-bold fs-lg-4">Total Number  of Auctions Created : </span>
                            <span class="text-primary font-inter-semi-bold fs-lg-4">{{ details.auction_created }}</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <table class="table table-bordered border-r-2 mb-0">
                <thead>
                <tr class="bg-1">
                    <th rowspan="1" colspan="2" class="pos-r">
                        <div class="font-inter-medium text-center fs-lg-2">
                            More Details
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="section-items">
                <tr>
                    <td class="font-inter-medium">
                        <div>
                            <span>Name : </span>
                            <span>{{ details.name }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div>
                            <span>Employee Number : </span>
                            <span>{{ details.employee_number }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div>
                            <span>Email : </span>
                            <span>{{ details.email }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div>
                            <span>Phone : </span>
                            <span>{{ details.mobile_number }}</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="font-inter-medium">
                        <div>
                            <span>Admin/User : </span>
                            <span v-if="details.is_bank_admin || details.is_ventura_admin">Admin</span>
                            <span v-else>User</span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div>
                            <span>Status : </span>
                            <span v-if="details.status">Active</span>
                            <span v-else>Inactive</span>
                        </div>
                    </td>
                </tr>
                <!-- Show Admin if abk or ventura admin -->
                <tr v-if="details.is_bank_admin || details.is_bank_user">
                    <td class="font-inter-medium">
                        <div>
                            <span>Bank Id : </span>
                            <span>{{ details.bank.bank_id }}</span>
                        </div>
                    </td>
                    <td class="font-inter-medium">
                        <div>
                            <span>Bank Name : </span>
                            <span>{{ details.bank.bank_name }}</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>

            <user-permission v-if="!details.is_customer && details.is_active
                && !details.is_bank_admin && !details.is_ventura_admin"
                             @success="loadDetails"></user-permission>

        </div>

        <div v-if="!details && !loading" class="text-center">No details available</div>

    </inner-page-card>

</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import UserPermission from '../../views/user-permission/UserPermission';

export default {
    name       : 'UserDetailsPage',
    components : { UserPermission },
    data () {
        return {
            loading       : false,
            details       : '',
            id            : this.$route.params.id,
            selectedIndex : this.$route.params.selectedIndex
        };
    },
    computed : {
        lineBackPath () {
            return this.selectedIndex ? '/manage-users/' + this.selectedIndex + '/' : '';
        }
    },
    mounted () {
        this.loadDetails();
    },

    methods : {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.userList.info, { id : this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
