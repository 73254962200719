import { render, staticRenderFns } from "./UserDetailsPage.vue?vue&type=template&id=ccad3248&scoped=true&"
import script from "./UserDetailsPage.vue?vue&type=script&lang=js&"
export * from "./UserDetailsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccad3248",
  null
  
)

export default component.exports